import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import TElement from '../components/T-element';
import { useQuery } from '@tanstack/react-query';
import { getGameResult } from '../api/result';
import { checkGameStatus } from '../api/home';
import Loading from '../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Result = () => {
  const navigate = useNavigate();
  const { data: checkData, isPending: isCheckPending } = useQuery({
    queryKey: ['check-status'],
    queryFn: () => checkGameStatus(),
  });

  useEffect(() => {
    if (checkData && checkData.status) {
      navigate('/');
    }
  }, [checkData]);

  const { data, isPending } = useQuery({
    queryKey: ['game-result'],
    queryFn: () => getGameResult(),
  });
  if (isPending || isCheckPending) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Result | DILEMMA</title>
      </Helmet>
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <div className="flex gap-6 md:gap-32">
          <div className="flex justify-center flex-col items-center gap-4 md:min-w-[350px] max-w-[350px]">
            <TElement
              percent={
                (100 * data.answers[0].count) /
                (data.answers[0].count + data.answers[1].count)
              }
            />
            <p className="text-2xl md:text-5xl text-border font-number italic font-medium">
              {(
                (100 * data.answers[0].count) /
                (data.answers[0].count + data.answers[1].count)
              ).toFixed(0)}
              %
            </p>
            <p
              className={classNames(
                'capitalize text-border text-lg md:text-3xl font-sans tracking-widest',
                { 'text-white underline': data?.answers[0].status }
              )}
            >
              {data?.answers[0].personality}
            </p>
            <p className="capitalize text-borer text-sm md:text-1xl text-border text-center">
              {data?.answers[0].name}
            </p>
          </div>
          <div className="flex justify-center flex-col items-center gap-4 md:min-w-[350px] max-w-[350px]">
            <TElement
              percent={
                (100 * data.answers[1].count) /
                (data.answers[0].count + data.answers[1].count)
              }
            />
            <p className="text-2xl md:text-5xl text-border font-number italic font-medium">
              {(
                (100 * data.answers[1].count) /
                (data.answers[0].count + data.answers[1].count)
              ).toFixed(0)}
              %
            </p>
            <p
              className={classNames(
                'capitalize text-border text-lg md:text-3xl font-sans tracking-widest',
                { 'text-white underline': data?.answers[1].status }
              )}
            >
              {data?.answers[1].personality}
            </p>
            <p className="capitalize text-borer text-sm md:text-1xl text-border text-center">
              {data?.answers[1].name}
            </p>
          </div>
        </div>
        <div>
          <p className="font-sans text-border text-xl md:text-4xl text-center">
            Does the poll seem
            <br /> off? Come back <br /> and check in later!
          </p>
        </div>
      </div>
    </>
  );
};

export default Result;
